import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
// import ExampleCard from './components/ExampleCard';
import axios from 'axios';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
// import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Volume from './components/Volume';
import Revenue from './components/Revenue';
import Urbin from './components/Urbin';
import L1Onboard from './components/L1Onboard';
import MaterialVolumeGraph from './components/MaterialVolumeGraph';
import TopUrbins from './components/TopUrbins';
import TopL1Graph from './components/TopL1Graph';
import TotalReach from './components/Awarness/TotalReach';
import Articles from './components/Awarness/Articles';
import GrievancesTotal from './components/Awarness/GrievancesTotal';
import GrievancesSolved from './components/Awarness/GrievancesSolved';
import PinWithInfoWindow from './components/PinWithInfoWindow';
import calendarIcon from '../../shared/img/calendar.svg';
import Loader from './components/Loader';

class ExamplePage extends Component {
  constructor() {
    super();
    this.state = { 
      data: '',
      loading: true,
      startDate: moment().startOf('week'),
      // startDate: moment().isoWeekday(1).startOf('isoWeek'),
      endDate: moment().endOf('week'),
      // endDate: moment().endOf('week'),
      ranges: {
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
        'Week Before the Last': [moment().subtract(2, 'week').startOf('week'), moment().subtract(2, 'week').endOf('week')]
      } 
    };
  }
    
  componentDidMount() {
    const startVal = this.state.startDate.format('YYYY-MM-DD').toString();
    const endVal = this.state.endDate.format('YYYY-MM-DD').toString();
    axios.get('https://kapps-api.kabadiwallaconnect.in/api/dashboard/', {
      params: {
        token: 'dashboardapinkadf91nksdnf91',
        start: startVal,
        end: endVal
      },
      headers: { origin: "*" }
    }).then(res => {
      this.setState({ data: res.data.data, loading: false });
    });
  }
  
  handleApply = (event, picker) => {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
      loading: true
    });
    const startVal = this.state.startDate.format('YYYY-MM-DD').toString();
    const endVal = this.state.endDate.format('YYYY-MM-DD').toString();
    axios.get('https://kapps-api.kabadiwallaconnect.in/api/dashboard/', {
      params: {
        token: 'dashboardapinkadf91nksdnf91',
        start: startVal,
        end: endVal
      },
      headers: { origin: "*" }
    }).then(res => {
      console.log(res.data.data);
      this.setState({ data: res.data.data, loading: false });
    });
  }
  
  renderLocationValues(points) {
    const mapValues=[];
    points.urbin.map(point => {
      point.flag = 'urbin';
      mapValues.push(point);
    });
    points.kw.map(kwPoint => {
      kwPoint.flag = 'kw';
      mapValues.push(kwPoint);
    });
    return mapValues;
  }
  
  
  render() {
    const startVal = this.state.startDate.format('DD-MM-YYYY').toString();
    const endVal = this.state.endDate.format('DD-MM-YYYY').toString();
    let label = startVal + '  to  ' + endVal;
    if (startVal === endVal) {
      label = startVal;
    }
    return (
      <Container className="dashboard">
        {
          this.state.loading ? (
            <Loader load={this.state.loading} />
          ) : (
            <div>
              <Row>
                <Col md={8} sm={6} xs={4}>
                  <div className="page-title bold-text">Dashboard</div>
                </Col>
                <Col className="cont" md={4} sm={6} xs={8}>
                  <div className="datePickerContainer">
                    <DateRangePicker
                      ranges={this.state.ranges}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onApply={this.handleApply}
                      opens="left"
                    >
                      <div className="input-group">
                        <input type="text" className="form-control" value={label} readOnly />
                        <span className="input-group-btn">
                          <div className="imgContainer">
                            <img src={calendarIcon} alt="img" height="20" />
                          </div>
                        </span>
                      </div>
                    </DateRangePicker>
                  </div>
                </Col>         
              </Row>
              <Row>
                <Volume value={this.state.data ? this.state.data.urbin.overall_volume : null} />
                <Revenue value={this.state.data ? this.state.data.urbin.overall_revenue : null} />
                <Urbin value={this.state.data ? this.state.data.urbin.total_urbins : null} />
                <L1Onboard value={this.state.data ? this.state.data.urbin.total_l1 : null} />
              </Row>
              <Row>
                {
                  this.state.data ? (
                    <MaterialVolumeGraph 
                      labels={this.state.data ? this.state.data.recykle.materials.x_axis : null}
                      axis={this.state.data ? this.state.data.recykle.materials.y_axis : null}
                    />
                  ) : null
                }
                {
                  this.state.data ? (
                    <TopL1Graph 
                      labels={this.state.data ? this.state.data.recykle.top_l1s.x_axis : null}
                      axis={this.state.data ? this.state.data.recykle.top_l1s.y_axis : null}
                    />
                  ) : null
                }
              </Row>
              <Row>
                {
                  this.state.data ? (
                    <TopUrbins urbins={this.state.data ? this.state.data.recykle.top_urbins : null} />
                  ) : null
                }
              </Row>
              {/* Awarness */}
              <Row>
                <Col md={12}>
                  <div className="page-title bold-text">Customer Engagement - Recykle</div>
                </Col>
              </Row>
              <Row>
                <TotalReach value={this.state.data ? this.state.data.recykle.total_users : null} />
                <Articles value={this.state.data ? this.state.data.recykle.total_articles : null} />
                <GrievancesTotal value={this.state.data ? this.state.data.recykle.total_grievance : null} />
                <GrievancesSolved value={this.state.data ? this.state.data.recykle.total_grievance_resolved : null} />
              </Row>
              {/* Map */}
              <Row>
                <Col md={12}>
                  <div className="page-title bold-text">Location of Urbins and L1</div>
                </Col>
              </Row>
              <Row>
                {
                  this.state.data ? (
                    <PinWithInfoWindow 
                      locationsPoints={this.state.data ? this.renderLocationValues(this.state.data.map) : null} 
                    />
                  ) : null
                }
              </Row>
            </div>
          )
        }
      </Container>
    );
  }
}

export default ExamplePage;
