import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import propTypes from 'prop-types';

const Revenue = (props) => {
  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget">
          <div className="card__title">
            <h5>NEW USERS ONBOARDED</h5>
          </div>
          <div className="dashboard__total dashboard__total--area">
            <p className="dashboard__total-stat">
              {props.value}
            </p>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

Revenue.propTypes = {
  value: propTypes.number
};

Revenue.defaultProps = {
  value: 0
};

export default Revenue;
