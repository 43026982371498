/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Col } from 'reactstrap';
import { compose, withProps, withStateHandlers } from 'recompose';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
import CloseIcon from 'mdi-react/CloseIcon';
// import { translate } from 'react-i18next';
import propTypes from 'prop-types';
import orangeMarker from '../../../shared/img/orangeMarker.svg';
import greenMarker from '../../../shared/img/greenMarker.svg';
import mapStyle from '../../../shared/googleMapStyle.json';

const MapWithAMarker = compose(
  withProps({
    // generate your API key
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBSDP8y2ydNDx6Fcloubk7bWZfx1c4s_6U',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className="map" style={{ height: '80vh' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withStateHandlers(() => ({
    isOpen: false,
    markerIndex: 0
  }), {
    onToggleOpen: () => (index) => ({
      isOpen: true,
      markerIndex: index
    }),
  }),
  withScriptjs,
  withGoogleMap,
)(props => (
  <GoogleMap
    defaultOptions={{ 
      scrollwheel: false, 
      streetViewControl: false, 
      mapTypeControl: false, 
      styles: mapStyle 
    }}
    defaultZoom={13}
    defaultCenter={{ lat: 13.049910, lng: 80.257222 }}
  >
    {props.isMarkerShown &&
      props.locationsPoints.map((point, index) => (
        <Marker 
          key={index} 
          icon={point.flag === 'kw' ? (orangeMarker) : (greenMarker)}
          position={{ lat: Number(point.latitude), lng: Number(point.longitude) }} 
          onClick={() => props.onToggleOpen(index)}
          // onClick={() => this.setState({ showInfoIndex: index })}
          // onClick={props.onMarkerClick(index)}
        >
          {props.isOpen && props.markerIndex === index &&
          <InfoBox options={{ closeBoxURL: '', enableEventPropagation: true }}>
            <div className="map__marker-label">
              <div className="map__marker-label-content">
                <div className="map__maker-label-close" onClick={props.onToggleOpen}><CloseIcon /></div>  
                {point.name}
              </div>
            </div>
          </InfoBox>}
        </Marker>
      ))
    }
  </GoogleMap>
));

class BasicMap extends React.PureComponent {
  render() {
    return (
      <Col xs={12} md={12} lg={12}>
        <div className="map__map-container">
          <MapWithAMarker 
            isMarkerShown 
            locationsPoints={this.props.locationsPoints} 
          />
        </div>
        <div className="legend">
          <div className="legend__legendBox">
            <div className="legend__legendIcon legend--urbin" />
            <div className="legend__text">Urbin</div>
          </div>
          <div className="legend__legendBox">
            <div className="legend__legendIcon legend--L1" />
            <div className="legend__text">L1</div>
          </div>
        </div>
      </Col>
    );
  }
}

BasicMap.propTypes = {
  locationsPoints: propTypes.array,
};

BasicMap.defaultProps = {
  locationsPoints: [],
};

// export default translate('common')(BasicMap);
export default BasicMap;
