import React from 'react';
import { Card, CardBody, Col, Table } from 'reactstrap';
// import { translate } from 'react-i18next';
import propTypes from 'prop-types';

const TopUrbins = (props) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title card__titleMargin">
          <h5>Top 5 Urbins</h5>
        </div>
        <Table className="table--bordered table--head-accent" responsive hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Bin ID</th>
              <th>Pickups</th>
              <th>Volume (Kg)</th>
              <th>Quality</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {
              props.urbins.map((urbin, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{urbin.bin_id}</td>
                    <td>{urbin.total_pickups}</td>
                    <td>{Math.round(urbin.volume)}</td>
                    <td>{Math.round(urbin.quality)}%</td>
                    <td>{urbin.location}</td>
                  </tr>
                ); 
              })
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
);

TopUrbins.propTypes = {
  urbins: propTypes.array
};

TopUrbins.defaultProps = {
  urbins: []
};


export default TopUrbins;
// export default translate('common')(HeadAccentTable);
