import React from 'react';
import propTypes from 'prop-types';

const Loader = (props) => (
  <div className="loader-container">
    {
      props.load === true ? (
        <div className="load__icon-wrap">
          <svg className="load__icon">
            <path fill="#3D5170" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
          </svg>
        </div>
      ) : null
    }
  </div>
);

Loader.propTypes = {
  load: propTypes.bool
};

Loader.defaultProps = {
  load: false
};

export default Loader;
