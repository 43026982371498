/* eslint-disable no-underscore-dangle,react/no-did-mount-set-state */
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
// import { translate } from 'react-i18next';
import propTypes from 'prop-types';
import 'chartjs-plugin-deferred';

const options = {
  animation: {
    duration: 400,
    easing: 'linear'
  },
  plugins: {
    deferred: {
      enabled: true,
      xOffset: 250,
      yOffset: '50%',
      delay: 800
    }
  },
  legend: {
    position: 'bottom',
    labels: {
      fontSize: 12,
      fontColor: '#818ea3',
      fontFamily: "'Open Sans', sans-serif",
      // fontWeight: 600
    },
  },
  tooltips: {
    enabled: true,
    bodyFontSize: 11,
    bodyFontColor: "white",
    bodyFontFamily: "'Open Sans', sans-serif",
    bodyFontStyle: "400",
    titleFontSize: 12,
    xPadding: 10,
    yPadding: 10,
    backgroundColor: '#374965',
    titleFontFamily: "'Open Sans', sans-serif",
    titleFontStyle: "600",
    cornerRadius: 3,
    displayColors: false
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: '#fff',
          borderDash: [1, 1],
        },
        ticks: {
          fontColor: '#66748b',
          fontFamily: "'Open Sans', sans-serif",
          fontSize: 10
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: 'rgb(204, 204, 204)',
          borderDash: [1, 3],
        },
        scaleLabel: {
          display: false,
          labelString: 'Volume (Kg)',
          fontSize: 12,
          fontColor: '#818ea3',
          fontFamily: "'Open Sans', sans-serif"
        },
        ticks: {
          fontColor: '#66748b',
          fontSize: 10,
        },
      },
    ],
  },
};

const renderLabelName = (labels) => {
  const materialName = [];
  for (let i = 0; i < labels.length; i++) {
    const index = labels[i].indexOf("(");
    const material = labels[i].slice(0, index);
    materialName.push(material);
  }
  return materialName;
};

const MaterialVolumeGraph = (props) => {
  // const { t } = this.props;
  const mapData = {
    labels: renderLabelName(props.labels),
    datasets: [
      {
        label: 'Volume (Kg)',
        backgroundColor: '#16C172',
        borderColor: '#16C172',
        borderWidth: 0,
        hoverBackgroundColor: '#18d07b',
        hoverBorderColor: '#18d07b',
        data: props.axis,
      },
    ],
  };
  
  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
        <CardBody>
          <div className="card__title card__titleMargin">
            <h5>Recovered material type (KG)</h5>
          </div>
          <Bar data={mapData} options={options} />
        </CardBody>
      </Card>
    </Col>
  );
};

MaterialVolumeGraph.propTypes = {
  labels: propTypes.array,
  axis: propTypes.array
};

MaterialVolumeGraph.defaultProps = {
  labels: [],
  axis: []
};

export default MaterialVolumeGraph;

// export default translate('common')(RandomAnimatedBars);
